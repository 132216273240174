import React from "react";

const MentalHealth = () => {
  return (
    <div>
      <h2>Mental Health</h2>
    </div>
  );
};

export default MentalHealth;
