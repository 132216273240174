import React from "react";

const FAQs = () => {
  return (
    <div>
      <h2>FAQs</h2>
    </div>
  );
};

export default FAQs;
