import React from "react";

const JobsAndInternships = () => {
  return (
    <div>
      <h2>Jobs And Internships</h2>
    </div>
  );
};

export default JobsAndInternships;
