import React from "react";

const BehavioralInterventions = () => {
  return (
    <div>
      <h2>Behavioral Interventions</h2>
    </div>
  );
};

export default BehavioralInterventions;
